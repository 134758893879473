<template>
  <div class="mt-1">
    <book-entry-form v-if="mode === 'I'"></book-entry-form>
    <salesmen-accounting v-if="mode === 'II'"></salesmen-accounting>
    <advance-payment v-if="mode === 'III'"></advance-payment>
    <insurance-ancillaries v-if="mode === 'IV'"></insurance-ancillaries>
    <various-accountings v-if="mode === 'V'"></various-accountings>
  </div>
</template>

<script>
import BookEntryForm from "@/components/accounting/book-entries/form/I";
import SalesmenAccounting from "@/components/accounting/book-entries/create-modes/II";
import AdvancePayment from "@/components/accounting/book-entries/create-modes/III";
import InsuranceAncillaries from "@/components/accounting/book-entries/create-modes/IV";
import VariousAccountings from "@/components/accounting/book-entries/create-modes/V";

export default {
  data() {
    return {
      mode: this.$route.params.mode ? this.$route.params.mode : "I",
      type: this.$route.params.type,
    };
  },
  components: {
    BookEntryForm,
    SalesmenAccounting,
    AdvancePayment,
    InsuranceAncillaries,
    VariousAccountings,
  },
};
</script>
