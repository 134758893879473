<template>
  <div class="mt-1 mx-3">
    <create-book-entry></create-book-entry>
  </div>
</template>

<script>
import CreateBookEntry from "@/components/accounting/book-entries/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CreateBookEntry,
  },
  created() {
    this.notifyQueue({
      text: "Crea Movimento Contabile",
      path: "book_entries.create",
      level: 4,
    });
  },
};
</script>
