<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div v-if="!isLoading">
      <div v-if="ok">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dettagli Generali" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="book_date"
                      :name="beForm[rep].book_date.label"
                      :label="beForm[rep].book_date.label"
                      v-model="form[rep].book_date"
                      :rules="getRules('book_date')"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              :header="`${
                source === 'registries'
                  ? 'Acconto Cliente'
                  : 'Acconto Produttore'
              }`"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-select
                      :name="beForm[rep].broker.label"
                      vid="broker_id"
                      :label="beForm[rep].broker.label"
                      v-model="form.broker_id"
                      :options="broker_options"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
                <b-row v-if="source === 'registries'">
                  <div class="form-group col-md-3">
                    <div>
                      <b-button-toolbar>
                        <b-button-group>
                          <base-input
                            name="Anagrafica"
                            label="Anagrafica"
                            v-model="form.inpt_label"
                            :readonly="true"
                            :rules="{ required: true }"
                          />
                          <b-button
                            class="mt-4 btn-quick"
                            size="sm"
                            text="Button"
                            variant="lisaweb"
                            title="Ricerca Veloce"
                            @click="openQuickSearchRegistry"
                            ><b-icon icon="search"></b-icon
                          ></b-button>
                          <b-button
                            class="mt-4 btn-quick ml-1"
                            size="sm"
                            text="Button"
                            variant="lisaweb"
                            @click="resetRegistryId"
                            title="Elimina Anagrafica"
                            ><b-icon icon="trash"></b-icon
                          ></b-button>
                        </b-button-group>
                      </b-button-toolbar>
                    </div>
                  </div>
                  <div class="registry_data col-md-3">
                    <span
                      v-if="registry_data"
                      class="info"
                      v-html="toInfoData(registry_data, 'registry')"
                    >
                    </span>
                  </div>
                  <quick-search-registry-modal
                    ref="quickSearchRegistry"
                    @input="handleRegistryModalInput"
                  >
                  </quick-search-registry-modal>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              header="Importo e descrizione"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-currency
                      :name="beForm[rep].gross.label"
                      :vid="beForm[rep].gross.label"
                      :label="beForm[rep].gross.label"
                      v-model="form[rep].gross"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('tax')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      :name="beForm[rep].title.label"
                      vid="title"
                      :label="beForm[rep].title.label"
                      v-model="form[rep].title"
                      :rules="getRules('title')"
                    />
                  </div>
                </b-row>

                <entry-details
                  ref="edRef"
                  :details="dtls"
                  v-model="form"
                  :rules="getRules('gross')"
                  :repository="repository"
                  amountField="gross"
                  enableRemainder
                  canDelete
                  @select="
                    selectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @unselect="
                    unselectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @delete="
                    deleteCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                ></entry-details>
              </b-card-text>
            </b-card>

            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button @click="customResetForm()" variant="lisaweb" size="sm"
                >Reset</b-button
              >
              <b-button
                @click="$router.back()"
                variant="outline-lisaweb"
                size="sm"
                class="btn-reset float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        {{ errorMessage }}
      </div>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseCurrency from "@/components/form/BaseCurrency";
import FormMixin from "@/mixins/FormMixin";
import EntryDetails from "@/components/form/EntryDetails";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  mixins: [FormMixin, EntryDetailsMixin, ShortcutMixin],
  data() {
    return {
      isLoading: false,
      source: this.$route.params.source ? this.$route.params.source : null,
      contractor_options: [
        { value: true, text: "Produttori" },
        { value: false, text: "Anagrafiche" },
      ],
      registry_data: null,
      repository: "book_entry",
      onlyRealTreasuries: true,
      skipAgencyTreasuries: true,
      skipTreasuries: this.getExcludedTreasuries()("OTHER"),
      treasury_options: [],
      treasuries: [],
      treasuriesCode: [],
      entryDetails: [],
      broker_options: [],
      form: {
        registry_id: null,
        broker_id: null,
        inpt_label: null,
        book_entry: {
          insurance_policy_number: null,
          book_date: moment().format("YYYY-MM-DD"),
          effective_date: null,
          sheet_date: null,
          registry: {},
          mandate_code_id: null,
          is_taxable: "N",
          title: null,
          broker: {},
          insurer_id: null,
          net: 0.0,
          tax: 0.0,
          gross: 0.0,
          agency_prov_take: 0.0,
          agency_prov_purchase: 0.0,
          saler_prov_take: 0.0,
          saler_prov_purchase: 0.0,
          various_accounting: {
            3: {},
          },
        },
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
        book_entries: {
          entry_details: {},
        },
      },
    };
  },
  methods: {
    toLocaleCurrency,
    toInfoData,
    initDefaultFormValues() {
      this.form.book_entry.is_taxable = "N";
      this.form[this.rep].effective_date = this.form[this.rep].book_date;
      this.form[this.rep].sheet_date = this.form[this.rep].book_date;
      this.form[this.rep].net = 0.0;
    },
    onSubmit() {
      if (this.checkEntryDetailsTotal()) {
        this.$showSnackbar({
          preset: "error",
          text: `Il lordo deve essere uguale al totale delle forme di pagamento`,
        });
        return;
      }
      this.isLoading = true;
      this.setContractor();
      this.initDefaultFormValues();
      this.store(this.repository)
        .then((response) => {
          let data = response.data.data;
          const entryDetailsPromises = this.storeEntryDetails(data);
          Promise.all(entryDetailsPromises)
            .then(() => {
              this.onSuccess();
              this.isLoading = false;
            })
            .catch((error) => {
              this.onFailure(error);
              this.isLoading = false;
            });
        })
        .catch((error) => {
          this.onFailure(error);
          this.isLoading = false;
        });
    },
    setContractor() {
      if (this.form.broker_id) {
        this.form[this.rep].broker = { [this.form.broker_id]: {} };
      }
      if (this.form.registry_id) {
        this.form[this.rep].registry = { [this.form.registry_id]: {} };
      }
    },
    deleteAll() {
      for (let d of this.dtls.filter((dtl) => dtl.selected)) {
        this.deleteCreateEntryDetail(
          this.form.book_entries.entry_details,
          d.id
        );
      }
    },
    customResetForm() {
      this.deleteAll();
      this.resetForm();
    },
    onSuccess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Movimento Contabile Creato (III)`,
      });
      this.shortcut("book_entries.index2", null, null, "");
    },
    checkEntryDetailsTotal() {
      return this.form[this.rep].gross != this.entryDetailsCreateTotal;
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    storeEntryDetails(book_entry_data) {
      let storeEntryDetails = [];
      this.form[this.rep_entrdtl].book_entry_id = book_entry_data.id;
      this.form[this.rep_entrdtl].detail_date = book_entry_data.book_date;
      Object.values(this.form.book_entries.entry_details)
        .filter((treasury) => treasury.type !== null && treasury.type !== null)
        .forEach((filteredTreasury) => {
          this.form[this.rep_entrdtl].gross = filteredTreasury.amount;
          this.form[this.rep_entrdtl].treasury_id = filteredTreasury.type;
          let trs = this.store(this.rep_entrdtl);
          storeEntryDetails.push(trs);
        });
      this.form[this.rep_entrdtl].gross = this.entryDetailsCreateTotal;
      this.form[this.rep_entrdtl].treasury_id = this.treasuriesCode.find(
        (el) => el.code === "AC"
      ).id;
      let trs = this.store(this.rep_entrdtl);
      storeEntryDetails.push(trs);

      return storeEntryDetails;
    },
    openQuickSearchRegistry() {
      this.form.registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label = null;
      this.form.registry_id = null;
    },
    handleRegistryModalInput(value) {
      this.form.registry_id = value.id; // the form v-model
      this.registry_data = value; // the whole record data
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getExcludedTreasuries: "excludedTreasuries",
    }),
  },
  components: {
    BaseIcon,
    BaseDatepicker,
    BaseCurrency,
    BaseInput,
    EntryDetails,
    BaseSelect,
    QuickSearchRegistryModal,
  },
  computed: {
    insuranceAncillariesGrossPremiumSum: {
      get() {
        return toLocaleCurrency(
          this.form[this.rep].net + this.form[this.rep].tax
        );
      },
    },
    entryDetailsCreateTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
  },
  beforeMount() {
    this.broker_options = this.getSalesmen();
  },
  mounted() {
    // le casse sono custom rispetto agli altri mode, => chiamo nel beforeMount
    // NOTE: Hook functions with the same name are merged into an array so that all of them will be called.
    // Mixin hooks will be called before the component’s own hooks.
    // this.initCreateEntryDetail(this.form.book_entries.entry_details);
    this.initDefaultFormValues();
  },
  created() {
    this.isLoading = true;
    const be = this.fetchCreateForm(this.repository);
    const ed = this.fetchCreateForm(this.rep_entrdtl);
    Promise.all([be, ed]).then(() => {
      this.isLoading = false;
    });
  },
};
</script>

<style scoped>
/* :deep(.card-header) {
  color: rgb(63, 60, 60);
  font-size: 14px;
  background-color: #e1ddda;
  border-radius: 0rem;
  text-transform: uppercase;
} */
</style>
