<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dettagli Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="book_date"
                        :name="beForm[rep].book_date.label"
                        :label="beForm[rep].book_date.label"
                        v-model="form[rep].book_date"
                        :rules="getRules('book_date')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                :header="`${
                  source === 'registries'
                    ? 'Acconto Cliente'
                    : 'Acconto Produttore'
                }`"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].broker.label"
                        vid="broker_id"
                        :label="beForm[rep].broker.label"
                        v-model="form.broker"
                        :options="broker_options"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                  <b-row v-if="source === 'registries'">
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              name="Anagrafica"
                              label="Anagrafica"
                              v-model="form.inpt_label_registry"
                              :readonly="true"
                              :rules="{ required: true }"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                            <b-button
                              class="mt-4 btn-quick ml-1"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              @click="resetRegistryId"
                              title="Elimina Anagrafica"
                              ><b-icon icon="trash"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Importo e descrizione"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].gross.label"
                        :vid="beForm[rep].gross.label"
                        :label="beForm[rep].gross.label"
                        v-model="form[rep].gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('tax')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="beForm[rep].title.label"
                        v-model="form[rep].title"
                        :rules="getRules('title')"
                      />
                    </div>
                  </b-row>

                  <entry-details
                    ref="edRef"
                    :details="Object.values(dtlscfg)"
                    v-model="form"
                    :rules="getRules('gross', 'entry_detail')"
                    noName
                    canDelete
                    amountField="gross"
                    :repository="repository"
                    enableRemainder
                    @select="
                      selectEditEntryDetail(
                        form.book_entries.entry_details,
                        ...arguments
                      )
                    "
                    @unselect="
                      unselectEditEntryDetail(
                        form.book_entries.entry_details,
                        ...arguments
                      )
                    "
                    @delete="
                      deleteEditEntryDetail(
                        form.book_entries.entry_details,
                        ...arguments
                      )
                    "
                  ></entry-details>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import BaseIcon from "@/components/BaseIcon";
import FormMixin from "@/mixins/FormMixin";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import { mapGetters } from "vuex";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseCurrency from "@/components/form/BaseCurrency";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import EntryDetails from "@/components/form/EntryDetails";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [FormMixin, ShortcutMixin, EntryDetailsMixin],
  data() {
    return {
      isLoading: true,
      repository: "book_entry",
      id: this.$route.params.id,
      item: this.$route.params.item,
      registry_data: null,
      onlyRealTreasuries: true,
      skipTreasuries: this.getExcludedTreasuries()("OTHER"),
      broker_options: [],
      treasury_options: [],
      treasuries: [],
      entryDetails: [],
      source: "brokers",
      form: {
        // Produttore
        broker: null,
        // Anagrafica
        registry: null,
        // Quicksearch Modal(Form)
        inpt_label_registry: "",
        quick_value: "",
        // Payload
        book_entry: {
          insurance_policy_number: null, //Numero Polizza
          book_date: null, //Data
          sheet_date: null,
          effective_date: null,
          registry: {},
          gross: null,
          mandate_code_id: null, //Codice Mandato Compagnia
          is_taxable: "Y",
          title: null, //Descrizione
          insurer_id: null, //Compagnia
          broker: {},
        },
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
        book_entries: {
          entry_details: {},
        },
      },
    };
  },
  components: {
    BaseIcon,
    QuickSearchRegistryModal,
    BaseInput,
    BaseDatepicker,
    BaseSelect,
    BaseCurrency,
    EntryDetails,
  },
  methods: {
    toInfoData,
    openQuickSearchRegistry() {
      this.form.registry = null; // the form v-model
      this.$bvModal.show("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label = null;
      this.form.registry = null;
    },
    handleRegistryModalInput(value) {
      this.form.registry = value.id; // the form v-model
      this.registry_data = value; // the whole record data
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    initDefaultValues() {
      // Anagrafica
      this.registry_data = this.item.registries.length
        ? this.item.registries[0]
        : null;
      if (this.registry_data) {
        this.form.registry = this.registry_data.id;
        this.form.inpt_label_registry = this.toInfoData(
          this.registry_data,
          "registryfullname"
        );
      }
      // Produttori
      this.broker_options = this.getSalesmen();
      this.form.broker = this.item.brokers.length
        ? this.item.brokers[0].id
        : null;
      // DepositType
      if (this.registry_data && this.form.broker) {
        this.source = "registries";
      }
      // REVIEW: workaround per disegnare la forma di pagamento vuota
      this.editEntryDetailsCompToggle = true;
    },
    setFormValues() {
      this.form[this.rep].effective_date = this.form[this.rep].book_date;
      // Anagrafica
      this.form[this.rep].registry = this.form.registry
        ? { [this.form.registry]: {} }
        : {};
      // Produttore
      this.form[this.rep].broker = this.form.broker
        ? { [this.form.broker]: {} }
        : {};
      // Tipologia di movimento Contabile
      if (this.isVariousAccounting) {
        const book_entry_id = this.item.various_accountings[0].id;
        this.form[this.rep]["various_accounting"] = {
          [book_entry_id]: {
            insurance_risk_id:
              this.item.various_accountings[0].pivot.insurance_risk_id,
          },
        };
      }
    },
    onSubmit() {
      if (this.form[this.rep].gross != this.entryDetailsEditTotal()) {
        this.$showSnackbar({
          preset: "error",
          text: `La somma delle forme di pagamento deve essere uguale al lordo`,
        });
        return;
      }
      const sbep = this.submitBookEntryPromise();
      const sedp = this.submitEntryDetailsPromise();
      const srd = this.submitRelatedDeposit();
      this.isLoading = true;
      Promise.all([sbep, sedp, srd])
        .then(() => {
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Movimento Contabile Modificato (III)`,
          });
          this.shortcut("deposit", null, null, "");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    submitRelatedDeposit() {
      const relatedDeferredItem = this.item.entry_details.find(
        (entry_detail) =>
          entry_detail.treasury.code === "AC" && entry_detail.gross > 0
      );

      if (relatedDeferredItem) {
        this.form[this.rep_entrdtl].book_entry_id = this.id;
        this.form[this.rep_entrdtl].gross = this.entryDetailsEditTotal();
        this.form[this.rep_entrdtl].treasury_id =
          relatedDeferredItem.treasury_id;
        this.form[this.rep_entrdtl].detail_date =
          relatedDeferredItem.detail_date;
        return this.update(this.rep_entrdtl, relatedDeferredItem.id);
      }
    },
    submitBookEntryPromise() {
      this.setFormValues();
      // Modifica il foglio cassa
      return this.update(this.repository, this.id);
    },
    submitEntryDetailsPromise() {
      // if (this.details.gross == this.sumEntryDetails) {
      // this.isLoading = true;
      let storedtls = [];
      // salvare con un entry_time incrementale i NewIndex
      let ut = moment().unix();
      for (const key in this.dtlscfg) {
        if (isNaN(parseInt(key))) {
          if (
            this.form.book_entries.entry_details[key].type &&
            this.form.book_entries.entry_details[key].amount
          ) {
            // sto creando, imposto entry_time
            this.$set(
              this.form[this.rep_entrdtl],
              "entry_time",
              moment(ut).format("x")
            );
            ut += 1;
            this.form[this.rep_entrdtl].book_entry_id = this.id;
            this.form[this.rep_entrdtl].gross =
              this.form.book_entries.entry_details[key].amount;
            this.form[this.rep_entrdtl].treasury_id =
              this.form.book_entries.entry_details[key].type;
            // CHECK non esiste this.details: uso this.form.book_entry.book_date???
            // this.form[this.rep_entrdtl].detail_date = this.details.book_date;
            this.form[this.rep_entrdtl].detail_date =
              this.form.book_entry.book_date;
            let sdtls = this.store(this.rep_entrdtl);
            storedtls.push(sdtls);
          }
        } else {
          this.form[this.rep_entrdtl].book_entry_id = this.id;
          this.form[this.rep_entrdtl].gross =
            this.form.book_entries.entry_details[key].amount;
          this.form[this.rep_entrdtl].treasury_id =
            this.form.book_entries.entry_details[key].type;
          // CHECK non esiste this.details: uso this.form.book_entry.book_date???
          // this.form[this.rep_entrdtl].detail_date = this.details.book_date;
          this.form[this.rep_entrdtl].detail_date =
            this.form.book_entry.book_date;
          let sdtls = this.update(this.rep_entrdtl, key);
          storedtls.push(sdtls);
        }
      }
      for (const key of this.deletableEntryDetailIds) {
        let sdtls = this.delete("entry_detail", key);
        storedtls.push(sdtls);
      }
      return Promise.all(storedtls);
    },
    entryDetailsEditTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    //Getters
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getExcludedTreasuries: "excludedTreasuries",
    }),
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    isVariousAccounting() {
      return this.item.various_accountings &&
        this.item.various_accountings.length
        ? true
        : false;
    },
  },
  created() {
    // const be = this.fetchCreateForm(this.repository);
    const be = this.fetchEditForm(this.repository, this.id);
    const ed = this.fetchCreateForm(this.rep_entrdtl); // serve per ottenere le rules sul campo gross dei details
    Promise.all([be, ed]).then(() => {
      this.initDefaultValues();
      this.entryDetails = this.item.entry_details;
      this.changeEntryDetails(this.form.book_entries.entry_details);
      this.isLoading = false;
    });
  },
};
</script>
