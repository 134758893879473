<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div v-if="!isLoading">
      <div v-if="ok">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dati Generali" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <div>
                      <b-button-toolbar>
                        <b-button-group>
                          <base-input
                            name="Anagrafica"
                            label="Anagrafica"
                            v-model="form.inpt_label"
                            :readonly="true"
                          />
                          <b-button
                            class="mt-4 btn-quick"
                            size="sm"
                            text="Button"
                            variant="lisaweb"
                            title="Ricerca Veloce"
                            @click="openQuickSearchRegistry"
                            :disabled="
                              type === 'edit' &&
                              (isImported ||
                                isConsolidated ||
                                hasBrokerStatement)
                            "
                            ><b-icon icon="search"></b-icon
                          ></b-button>
                          <b-button
                            class="mt-4 btn-quick ml-1"
                            size="sm"
                            text="Button"
                            variant="lisaweb"
                            title="Elimina Anagrafica"
                            @click="resetRegistryId"
                            v-if="form.inpt_label"
                            :disabled="
                              type === 'edit' &&
                              (isImported ||
                                isConsolidated ||
                                hasBrokerStatement)
                            "
                            ><b-icon icon="trash"></b-icon
                          ></b-button>
                        </b-button-group>
                      </b-button-toolbar>
                    </div>
                  </div>
                  <div class="registry_data col-md-3">
                    <span
                      v-if="registry_data"
                      class="info"
                      v-html="toInfoData(registry_data, 'registry')"
                    >
                    </span>
                  </div>
                  <quick-search-registry-modal
                    ref="quickSearchRegistry"
                    @input="handleRegistryModalInput"
                  >
                  </quick-search-registry-modal>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-input
                      vid="insurance_policy_number"
                      :name="beForm[rep].insurance_policy_number.label"
                      :label="beForm[rep].insurance_policy_number.label"
                      v-model="form[rep].insurance_policy_number"
                      :rules="getRules('insurance_policy_number')"
                      :readonly="!isVariousAccounting"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="book_date"
                      :name="beForm[rep].book_date.label"
                      :label="beForm[rep].book_date.label"
                      v-model="form[rep].book_date"
                      :rules="getRules('book_date')"
                      :disabled="
                        type === 'edit' &&
                        (isImported || isConsolidated || hasBrokerStatement)
                      "
                      :readonly="
                        type === 'edit' &&
                        (isImported || isConsolidated || hasBrokerStatement)
                      "
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="sheet_date"
                      :name="beForm[rep].sheet_date.label"
                      :label="beForm[rep].sheet_date.label"
                      v-model="form[rep].sheet_date"
                      :rules="getRules('sheet_date')"
                      :disabled="
                        type === 'edit' &&
                        (isImported || isConsolidated || hasBrokerStatement)
                      "
                      :readonly="
                        type === 'edit' &&
                        (isImported || isConsolidated || hasBrokerStatement)
                      "
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="effective_date"
                      :name="beForm[rep].effective_date.label"
                      :label="beForm[rep].effective_date.label"
                      v-model="form[rep].effective_date"
                      :rules="getRules('effective_date')"
                      :disabled="
                        type === 'edit' &&
                        (isImported ||
                          isConsolidated ||
                          hasBrokerStatement ||
                          isInsuranceAncillaries)
                      "
                      :readonly="
                        type === 'edit' &&
                        (isImported ||
                          isConsolidated ||
                          hasBrokerStatement ||
                          isInsuranceAncillaries)
                      "
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="col-md-3">
                    <base-select
                      :name="beForm[rep].insurer_id.label"
                      vid="insurer_id"
                      :label="beForm[rep].insurer_id.label"
                      :options="companies"
                      v-model="form[rep].insurer_id"
                      :rules="getRules('insurer_id')"
                      @input="onInputInsurer"
                      :disabled="
                        type === 'edit' &&
                        ((isImported && !!form[rep].insurer_id) ||
                          (!isImported && isInsuranceAncillaries))
                      "
                    />
                  </div>
                  <!-- <div class="col-md-3">
                    <base-select
                      name="Rischio"
                      vid="risk"
                      label="Rischio"
                      :options="risks"
                      v-model="risk_type_id"
                      :multiple="true"
                      @input="onInputRisk"
                    />
                  </div> -->
                  <div class="col-md-3">
                    <base-select
                      name="Ramo"
                      vid="branch"
                      label="Ramo"
                      :options="risk_branches"
                      v-model="risk_branch_id"
                      :multiple="false"
                      @input="onInputBranch"
                      :disabled="
                        type === 'edit' &&
                        ((isImported && !!risk_branch_id) ||
                          (!isImported && isInsuranceAncillaries))
                      "
                    />
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="Prodotto"
                      vid="product"
                      label="Prodotto"
                      :options="prdct"
                      v-model="insurance_risk_id"
                      @select="onSelectProduct"
                      @remove="onDeleteProduct"
                      :rules="{ required: true }"
                      :disabled="
                        type === 'edit' &&
                        ((isImported && !!insurance_risk_id) ||
                          (!isImported && isInsuranceAncillaries))
                      "
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-select
                      vid="mandate_code_id"
                      :name="beForm[rep].mandate_code_id.label"
                      :label="beForm[rep].mandate_code_id.label"
                      :options="mandate_code_options"
                      v-model="form[rep].mandate_code_id"
                      placeholder="Seleziona un codice mandato"
                      :rules="{ required: true }"
                      :disabled="
                        !form[rep].insurer_id ||
                        (type === 'edit' &&
                          isImported &&
                          !!form[rep].mandate_code_id) ||
                        (!isImported && isInsuranceAncillaries)
                      "
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      :name="beForm[rep].title.label"
                      vid="title"
                      :label="beForm[rep].title.label"
                      v-model="form[rep].title"
                      :rules="getRules('title')"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-select
                      :name="beForm[rep].broker.label"
                      vid="broker"
                      :label="beForm[rep].broker.label"
                      v-model="form.broker"
                      :options="broker_options"
                      :rules="{ required: true }"
                      :disabled="isConsolidated || hasBrokerStatement"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="book_tags"
                      label="Codice Documento"
                      v-model="form.book_tag"
                      :options="book_tags_opt"
                      :disabled="
                        type === 'edit' &&
                        ((isImported && !!form.book_tag) ||
                          (!isImported && isInsuranceAncillaries))
                      "
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card header="Premio" header-tag="header" class="mt-1">
              <div @click="onClickSwitchBookEntry()" class="switch">
                <b-form-checkbox
                  v-model="isBookEntryBreakdown"
                  name="check-button"
                  switch
                  :disabled="
                    !insurance_risk_id ||
                    bookEntryBreakdowns.length == 0 ||
                    isLoadingBreakdowns
                  "
                  @change="onChangeSwitchBookEntry()"
                >
                  {{
                    isBookEntryBreakdown
                      ? "Disabilita Scorpori"
                      : "Abilita Scorpori"
                  }}
                </b-form-checkbox>
              </div>
              <b-card-text v-if="!isBookEntryBreakdown">
                <b-row>
                  <div class="form-group col-md-3">
                    <base-currency
                      :name="beForm[rep].net.label"
                      vid="net"
                      :label="`${
                        agencyHasFee && insurerHasFee
                          ? getDictionary('premio_net')
                          : getDictionary('net')
                      }`"
                      v-model="form[rep].net"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('net')"
                      :readonly="!insurance_risk_id"
                      :disabled="
                        type === 'edit' &&
                        (isImported || (!isImported && isInsuranceAncillaries))
                      "
                      @blur="onNetBlur"
                      @keyup="onInputNet"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      :name="beForm[rep].tax.label"
                      vid="tax"
                      :label="`${
                        agencyHasFee && insurerHasFee
                          ? getDictionary('premio_tax')
                          : getDictionary('tax')
                      }`"
                      v-model="form[rep].tax"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('tax')"
                      :readonly="!insurance_risk_id"
                      :disabled="
                        type === 'edit' &&
                        (isImported || (!isImported && isInsuranceAncillaries))
                      "
                      @keyup="onInputTax"
                    />
                  </div>
                  <div class="form-group col-md-3" data-test="fk-or-not">
                    <base-currency
                      :name="`${
                        agencyHasFee && insurerHasFee
                          ? getDictionary('premio_gross_fk')
                          : getDictionary('premio_gross')
                      }`"
                      vid="gross"
                      :label="`${
                        agencyHasFee && insurerHasFee
                          ? getDictionary('premio_gross')
                          : getDictionary('gross')
                      }`"
                      v-model="form[rep].gross"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :readonly="!insurance_risk_id"
                      :disabled="type === 'edit' && isImported"
                      :rules="getRules('grosgetRuless')"
                      @blur="onInputGross"
                    />
                  </div>
                </b-row>
              </b-card-text>
              <b-card-text v-if="isBookEntryBreakdown">
                <div v-if="bookEntryBreakdowns.length">
                  <b-overlay center :show="isLoadingBreakdowns" rounded="sm">
                    <b-table
                      hover
                      :items="bookEntryBreakdowns"
                      :fields="fields"
                      ref="bookEntryBreakdowns"
                    >
                      <template v-slot:cell(net)="row">
                        <div class="form-group col-md-7">
                          <base-currency
                            :name="beForm[rep].net.label"
                            :vid="`net-${row.item.id}`"
                            :label="beForm[rep].net.label"
                            v-model="row.item.net.value"
                            :options="{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            }"
                            :readonly="
                              row.item.net.is_net === 'Y' ? false : true
                            "
                          />
                        </div>
                      </template>
                      <template v-slot:cell(tax)="row">
                        <div class="form-group col-md-7">
                          <base-currency
                            name="Tasse"
                            :vid="`tax-${row.item.id}`"
                            label="Tasse"
                            v-model="row.item.tax.value"
                            :options="{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            }"
                            :readonly="
                              row.item.tax.is_tax === 'Y' ? false : true
                            "
                          />
                        </div>
                      </template>
                      <template v-slot:cell(grossrow)="row">
                        <div class="form-group col-md-7">
                          <base-input
                            name="grossrow"
                            :vid="`grossrow-${row.item.id}`"
                            label="Lordo Riga"
                            custom_type="currency"
                            :value="
                              grossRowBookEntry(
                                row.item.tax.value,
                                row.item.net.value
                              )
                            "
                            :readonly="true"
                          />
                        </div>
                      </template>
                      <template slot="bottom-row">
                        <td>Totale</td>
                        <td>
                          <div class="form-group col-md-7">
                            <base-label
                              :value="bookEntryNetSum"
                              class="net-sum-label"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-7">
                            <base-label
                              :value="bookEntryTaxSum"
                              class="tax-sum-label"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-7">
                            <base-label
                              class="gross-sum-label"
                              :value="bookEntryGrossSum"
                            />
                          </div>
                        </td>
                      </template>
                    </b-table>
                    <template #overlay>
                      <div class="text-center">
                        <base-icon name="loading" width="35" height="35" />
                        <p id="cancel-label">Operazione in corso...</p>
                      </div>
                    </template>
                  </b-overlay>
                </div>
              </b-card-text>

              <!-- -->
              <template v-if="agencyHasFee && insurerHasFee">
                <b-row>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="fee_net"
                      vid="fee_net"
                      :label="getDictionary('diritto_net')"
                      v-model="form[rep].fee_net"
                      :readonly="!insurerHasFee"
                      :disabled="type === 'edit' && isImported"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      @keyup="onInputNetDiritti"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="fee_tax"
                      vid="fee_tax"
                      :label="getDictionary('diritto_tax')"
                      v-model="form[rep].fee_tax"
                      :readonly="!insurerHasFee"
                      :disabled="type === 'edit' && isImported"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      @keyup="onInputTaxDiritti"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="fee_gross"
                      vid="fee_gross"
                      :label="getDictionary('diritto_gross')"
                      v-model="form[rep].fee_gross"
                      :disabled="type === 'edit' && isImported"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      @blur="onInputGrossDiritti"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3 offset-md-6">
                    <base-currency
                      :name="beForm[rep].gross.label"
                      vid="gross"
                      :label="getDictionary('total_to_be_invoiced_gross')"
                      v-model="grandGross"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :readonly="true"
                      :rules="getRules('gross')"
                    />
                  </div>
                </b-row>
              </template>
            </b-card>
            <b-card
              header="Provvigioni di Agenzia"
              header-tag="header"
              class="mt-1"
            >
              <div
                @click="onClickSwitchAgency()"
                class="switch"
                v-if="type === 'create'"
              >
                <b-form-checkbox
                  v-model="isAgencyBreakdown"
                  name="check-button"
                  switch
                  :disabled="!isBookEntryBreakdown"
                  @change="onChangeSwitchAgency()"
                >
                  {{
                    isAgencyBreakdown
                      ? "Disabilita Scorpori"
                      : "Abilita Scorpori"
                  }}
                </b-form-checkbox>
              </div>
              <b-card-text v-if="!isAgencyBreakdown">
                <b-row>
                  <div class="form-group col-md-3">
                    <base-currency
                      :name="beForm[rep].agency_prov_take.label"
                      vid="agency_prov_take"
                      :label="`
                        ${beForm[rep].agency_prov_take.label} ${
                        isLoadingSpecifications ? ' (in caricamento...)' : ''
                      }
                      `"
                      v-model="form[rep].agency_prov_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :disabled="
                        type === 'edit' &&
                        (isImported ||
                          isConsolidated ||
                          hasBrokerStatement ||
                          hasBreakdowns)
                      "
                      :rules="getRules('agency_prov_take')"
                      :readonly="isLoadingSpecifications"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      :name="beForm[rep].agency_prov_purchase.label"
                      vid="agency_prov_purchase"
                      :label="`
                        ${beForm[rep].agency_prov_purchase.label} ${
                        isLoadingSpecifications ? ' (in caricamento...)' : ''
                      }
                      `"
                      v-model="form[rep].agency_prov_purchase"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :disabled="
                        type === 'edit' &&
                        (isImported ||
                          isConsolidated ||
                          hasBrokerStatement ||
                          hasBreakdowns)
                      "
                      :rules="getRules('agency_prov_purchase')"
                      :readonly="isLoadingSpecifications"
                    />
                  </div>
                </b-row>
              </b-card-text>
              <b-card-text v-if="isAgencyBreakdown">
                <div v-if="agencyProvBreakdowns.length">
                  <b-overlay center :show="isLoadingBreakdowns" rounded="sm">
                    <b-table
                      hover
                      :items="agencyProvBreakdowns"
                      :fields="agencyFields"
                      ref="agencyBreakdowns"
                    >
                      <template v-slot:cell(take)="row">
                        <div class="form-group col-md-7">
                          <base-currency
                            :name="row.field.label"
                            :vid="`agency_take-${row.item.id}`"
                            :label="row.field.label"
                            v-model="row.item.take.value"
                            custom_type="currency"
                            :options="{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            }"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(purchase)="row">
                        <div class="form-group col-md-7">
                          <base-currency
                            :name="row.field.label"
                            :vid="`agency_purchase-${row.item.id}`"
                            :label="row.field.label"
                            v-model="row.item.purchase.value"
                            :options="{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            }"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(gross)="row">
                        <div class="form-group col-md-7">
                          <base-input
                            :name="row.field.label"
                            vid="agency_prov_purchase"
                            :label="row.field.label"
                            custom_type="currency"
                            :value="grossRow(row.item.id)"
                            :rules="getRules('agency_prov_purchase')"
                            :readonly="true"
                          />
                        </div>
                      </template>
                      <template slot="bottom-row">
                        <td>Totale</td>
                        <td>
                          <div class="form-group col-md-7">
                            <base-label />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-7">
                            <base-label
                              class="take-sum-label"
                              :value="agencyTakeSum"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-7">
                            <base-label
                              class="purchase-sum-label"
                              :value="agencyPurchaseSum"
                            />
                          </div>
                        </td>
                      </template>
                    </b-table>
                    <template #overlay>
                      <div class="text-center">
                        <base-icon name="loading" width="35" height="35" />
                        <p id="cancel-label">Operazione in corso...</p>
                      </div>
                    </template>
                  </b-overlay>
                </div>
              </b-card-text>
            </b-card>
            <b-card
              header="Provvigioni del Produttore"
              header-tag="header"
              class="mt-1"
            >
              <b-row>
                <div class="form-group col-md-3" v-if="type === 'create'">
                  <b-form-checkbox
                    v-model="isSalerBreakdown"
                    name="check-button"
                    switch
                    :disabled="!isBookEntryBreakdown"
                    @change="onChangeSwitchSaler()"
                  >
                    <p @click="onClickSwitchSaler()">
                      {{
                        isSalerBreakdown
                          ? "Scorpori Produttore"
                          : "Provv. Produttore"
                      }}
                    </p>
                  </b-form-checkbox>
                </div>
                <div class="form-group col-md-3">
                  <b-form-checkbox
                    v-model="computeBrokerStatementMode"
                    name="check-button"
                    switch
                    :disabled="
                      (type === 'create' && isSalerBreakdown) ||
                      (type === 'edit' && (isImported || hasBreakdowns))
                    "
                  >
                    Calcola in fase rendicontale utilizzando il capitolato
                    provvigionale
                  </b-form-checkbox>
                  <!-- @change="onComputeBrokerStatementMode" -->
                </div>
              </b-row>

              <b-card-text v-if="!isSalerBreakdown">
                <b-row>
                  <div class="form-group col-md-3">
                    <base-currency
                      :name="beForm[rep].saler_prov_take.label"
                      vid="saler_prov_take"
                      :label="beForm[rep].saler_prov_take.label"
                      v-model="form[rep].saler_prov_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :disabled="
                        (type === 'create' && computeBrokerStatementMode) ||
                        (type === 'edit' &&
                          (isImported ||
                            isConsolidated ||
                            hasBrokerStatement ||
                            hasBreakdowns ||
                            // !insurerHasFee ||
                            computeBrokerStatementMode))
                      "
                      :rules="getRules('saler_prov_take')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      :name="beForm[rep].saler_prov_purchase.label"
                      vid="saler_prov_purchase"
                      :label="beForm[rep].saler_prov_purchase.label"
                      v-model="form[rep].saler_prov_purchase"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :disabled="
                        (type === 'create' && computeBrokerStatementMode) ||
                        (type === 'edit' &&
                          (isImported ||
                            isConsolidated ||
                            hasBrokerStatement ||
                            hasBreakdowns ||
                            // !insurerHasFee ||
                            computeBrokerStatementMode))
                      "
                      :rules="getRules('saler_prov_purchase')"
                    />
                  </div>
                </b-row>
              </b-card-text>
              <b-card-text v-if="isSalerBreakdown">
                <div v-if="salerProvBreakdowns.length">
                  <b-overlay center :show="isLoadingBreakdowns" rounded="sm">
                    <b-table
                      hover
                      :items="salerProvBreakdowns"
                      :fields="salerFields"
                      ref="salerProvBreakdowns"
                    >
                      <template v-slot:cell(agencytake)="row">
                        <div class="form-group col-md-18">
                          <base-input
                            :name="row.field.label"
                            :vid="`agency_take-${row.item.id}`"
                            :label="row.field.label"
                            custom_type="currency"
                            :value="agencyTakeRow(row.item.id)"
                            :readonly="true"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(take)="row">
                        <div class="form-group col-md-18">
                          <base-currency
                            :name="row.field.label"
                            :vid="`saler_take-${row.item.id}`"
                            :label="row.field.label"
                            v-model="row.item.take.value"
                            :options="{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            }"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(agencypurchase)="row">
                        <div class="form-group col-md-18">
                          <base-input
                            :name="row.field.label"
                            :vid="`agency_purchase-${row.item.id}`"
                            :label="row.field.label"
                            custom_type="currency"
                            :value="agencyPurchaseRow(row.item.id)"
                            :readonly="true"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(purchase)="row">
                        <div class="form-group col-md-18">
                          <base-currency
                            :name="row.field.label"
                            :vid="`saler_purchase-${row.item.id}`"
                            :label="row.field.label"
                            v-model="row.item.purchase.value"
                            :options="{
                              currency: 'EUR',
                              locale: 'it-IT',
                              precision: 2,
                            }"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(gross)="row">
                        <div class="form-group col-md-18">
                          <base-input
                            :name="row.field.label"
                            :vid="`total_row-${row.item.id}`"
                            :label="row.field.label"
                            custom_type="currency"
                            :value="grossRow(row.item.id)"
                            :readonly="true"
                          />
                        </div>
                      </template>
                      <template slot="bottom-row">
                        <td>Totale</td>
                        <td>
                          <div class="form-group col-md-18">
                            <base-label />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-18">
                            <base-label />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-18">
                            <base-label
                              class="take-sum-label"
                              :value="salerTakeSum"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-18">
                            <base-label />
                          </div>
                        </td>
                        <td>
                          <div class="form-group col-md-18">
                            <base-label
                              class="purchase-sum-label"
                              :value="salerPurchaseSum"
                            />
                          </div>
                        </td>
                      </template>
                    </b-table>
                    <template #overlay>
                      <div class="text-center">
                        <base-icon name="loading" width="35" height="35" />
                        <p id="cancel-label">Operazione in corso...</p>
                      </div>
                    </template>
                  </b-overlay>
                </div>
              </b-card-text>
              <b-row>
                <div
                  class="form-group col-md-3"
                  v-if="
                    (type === 'create' && agencyHasFee && insurerHasFee) ||
                    (type === 'edit' && agencyHasFee)
                  "
                >
                  <base-currency
                    name="saler_fee_take"
                    vid="saler_fee_take"
                    label="Diritti di incasso produttore"
                    v-model="form[rep].saler_fee_take"
                    :disabled="!insurerHasFee || computeBrokerStatementMode"
                    :options="{
                      currency: 'EUR',
                      locale: 'it-IT',
                      precision: 2,
                    }"
                  />
                </div>
              </b-row>
            </b-card>
            <b-card
              header="Forme di Pagamento"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <div v-if="type === 'edit' && !canEdit">
                  <b-table
                    v-if="hasTreasuryRelation"
                    striped
                    hover
                    :items="entryDetails"
                    :fields="entryDetailFields"
                  >
                    <template v-slot:cell(treasury_code)="row">
                      {{ row.item?.treasury?.code }}
                    </template>
                    <template v-slot:cell(treasury_title)="row">
                      {{ row.item?.treasury?.title }}
                    </template>
                    <template v-slot:cell(amount)="row">
                      {{ toLocaleCurrency(row.item.gross) }}
                    </template>
                  </b-table>
                  <p v-if="!hasTreasuryRelation">
                    Non ci sono forme di pagamento disponibili
                  </p>
                </div>
                <div
                  v-else-if="type === 'create' || (type === 'edit' && canEdit)"
                >
                  <template v-if="type === 'edit' && canEdit">
                    <b-button
                      v-if="item.gross < 0 && sumEntryDetails > item.gross"
                      type="button"
                      variant="lisaweb"
                      size="sm"
                      class="mb-2"
                      @click="onAcconto()"
                      >Chiudi con acconto
                    </b-button>
                  </template>
                  <entry-details
                    ref="edRef"
                    :details="type === 'create' ? dtls : Object.values(dtlscfg)"
                    v-model="form"
                    :rules="
                      type === 'create'
                        ? getRules('gross')
                        : getRules('gross', 'entry_detail')
                    "
                    :repository="repository"
                    amountField="byRemainderFunction"
                    :remainderFunction="byRemainderFunctionBookEntry"
                    :noName="type === 'create' ? false : true"
                    enableRemainder
                    canDelete
                    @select="
                      type === 'create'
                        ? selectCreateEntryDetail(
                            form.book_entries.entry_details,
                            ...arguments
                          )
                        : selectEditEntryDetail(
                            form.book_entries.entry_details,
                            ...arguments
                          )
                    "
                    @unselect="
                      type === 'create'
                        ? unselectCreateEntryDetail(
                            form.book_entries.entry_details,
                            ...arguments
                          )
                        : unselectEditEntryDetail(
                            form.book_entries.entry_details,
                            ...arguments
                          )
                    "
                    @delete="
                      type === 'create'
                        ? deleteCreateEntryDetail(
                            form.book_entries.entry_details,
                            ...arguments
                          )
                        : deleteEditEntryDetail(
                            form.book_entries.entry_details,
                            ...arguments
                          )
                    "
                  ></entry-details>
                </div>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button
                @click="customResetForm()"
                variant="lisaweb"
                size="sm"
                v-if="type === 'create'"
                >Reset</b-button
              >
              <b-button
                @click="$router.back()"
                variant="outline-lisaweb"
                size="sm"
                class="btn-reset float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        {{ errorMessage }}
      </div>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import EntryDetails from "@/components/form/EntryDetails";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseCurrency from "@/components/form/BaseCurrency";

import BaseLabel from "@/components/form/BaseLabel";

import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData } from "@/utils/transforms";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";

import moment from "moment";

import {
  sumBreakdowns,
  dataMappingPremium,
  dataMappingProv,
} from "@/utils/breakdowns";

export default {
  mixins: [FormMixin, ShortcutMixin, EntryDetailsMixin],
  data() {
    return {
      isLoading: true,
      type: this.$route.params.type,
      mode: this.$route.params.mode ? this.$route.params.mode : "I",
      item:
        this.$route.params.type === "create" ? null : this.$route.params.item,
      id: this.$route.params.type === "create" ? null : this.$route.params.id,
      titleId: this.$route.params.titleId ? this.$route.params.titleId : null,
      various_accountings_options: [],
      variousAccountingId: null,
      extraTreasuries:
        this.$route.params.type === "create"
          ? ["AB", "SS", "AC"]
          : ["AB", "SS"],
      onlyRealTreasuries: true,
      skipAgencyTreasuries: true,
      skipTreasuries: this.getExcludedTreasuries()("OTHER"),
      insurerHasFee: false,
      consolidated_data: null,
      canEdit: false,
      DEFERRED: this.$route.params.type === "create" ? null : "SS",
      ADVANCE_PAYMENT: this.$route.params.type === "create" ? null : "AB",
      REVENUE: this.$route.params.type === "create" ? null : "EN",
      COASS_COMMISSIONS: this.$route.params.type === "create" ? null : "COP",
      COASS_PREMIO: this.$route.params.type === "create" ? null : "COC",
      COMMISSIONS: this.$route.params.type === "create" ? null : "PR",
      //QuickSearch Modal
      registry_data: null,
      //Repository
      repository: "book_entry",
      //EntryDetails
      entryDetails: [],
      entryDetailFields:
        this.$route.params.type === "create"
          ? null
          : [
              { key: "treasury_code", label: "Codice Cassa" },
              { key: "treasury_title", label: "Tipo Cassa" },
              { key: "amount", label: "Importo" },
            ],
      //Treasuries
      treasuries: [],
      treasuriesCode: [],
      checkTreasuryRelation: [],
      //Multiselect Options
      companies: [],
      risk_branches: [],
      mandate_code_options: [],
      risks: [],
      products: [],
      broker_options: [],
      book_tags_opt: [],
      //Multiselect V-model
      insurance_risk_id: null,
      agency_broker_id: null,
      risk_branch_id: null,
      risk_type_id: null,
      //Breakdownables Data
      bookEntryBreakdowns: [],
      agencyProvBreakdowns: [],
      salerProvBreakdowns: [],
      insuranceAncillariesAgencyBreakdowns: [],
      insuranceAncillariesSalerBreakdowns: [],
      //Section flags
      isBookEntryBreakdown: false,
      isAgencyBreakdown: false,
      isSalerBreakdown: false,
      computeBrokerStatementMode:
        this.$route.params.type === "create" ? true : false,
      //Ajax call flags
      isLoadingBreakdowns: false,
      isLoadingSpecifications: false,
      //insuranceAncillariesBreakdowns
      insuranceAncillariesBreakdowns: [],
      //SalerBreakdowns
      salerFields:
        this.$route.params.type === "create"
          ? [
              {
                key: "title",
                label: "Scorporo",
              },
              {
                key: "gross",
                label: "Totale",
              },
              {
                key: "agencytake",
                label: "Inc. Ag.",
              },
              {
                key: "take",
                label: "Incasso",
              },
              {
                key: "agencypurchase",
                label: "Acq. Ag.",
              },
              {
                key: "purchase",
                label: "Acquisto",
              },
            ]
          : null,
      //AgencyBreakdowns
      agencyFields:
        this.$route.params.type === "create"
          ? [
              {
                key: "title",
                label: "Scorporo",
              },
              {
                key: "gross",
                label: "Totale",
              },
              {
                key: "take",
                label: "Incasso",
              },
              {
                key: "purchase",
                label: "Acquisto",
              },
            ]
          : null,
      //Fields
      fields:
        this.$route.params.type === "create"
          ? [
              {
                key: "title",
                label: "Scorporo",
              },
              {
                key: "net",
                label: "Netto",
              },
              {
                key: "tax",
                label: "Tasse",
              },
              {
                key: "grossRow",
                label: "Lordo Riga",
              },
            ]
          : null,
      //Agency Breakdowns v-model
      net_agency_take: null,
      tax_agency_take: null,
      net_agency_purchase: null,
      tax_agency_purchase: null,
      //Saler Breakdowns v-model
      net_saler_take: null,
      tax_saler_take: null,
      net_saler_purchase: null,
      tax_saler_purchase: null,

      form: {
        various_accounting_id: null,
        // Anagrafica
        registry: null,
        // Produttore
        broker: null,
        // Codice Documento
        book_tag: null,
        //Quicksearch Modal(Form)
        inpt_label: "",
        quick_value: "",
        //Foglio Cassa(Form)
        book_entry: {
          insurance_policy_number: null, //Numero Polizza
          book_date: null, //Data
          broker: {},
          sheet_date: null,
          effective_date: null,
          registry: {},
          mandate_code_id: null, //Codice Mandato Compagnia
          is_taxable: "Y",
          title: null, //Descrizione
          insurer_id: null, //Compagnia
          net: 0.0, //Netto
          tax: 0.0, //Tasse
          gross: 0.0, //Lordo
          agency_prov_take: 0.0, //Provv. agenzia d'incasso
          agency_prov_purchase: 0.0, //Provv. agenzia di acquisto
          saler_prov_take: 0.0, //Provv. prod. d'incasso
          saler_prov_purchase: 0.0, //provv. prod. di acquisto
          various_accounting: {},
          fee_net: 0.0,
          fee_tax: 0.0,
          fee_gross: 0.0,
          saler_fee_take: 0.0,
        },
        //Scorpori
        breakdown: {},
        //Dettagli(Form)
        entry_detail: {
          book_entry_id: null, //Foglio Cassa
          treasury_id: null, //Cassa
          gross: null, //Lordo
          detail_date: null, //Data Dettaglio
        },
        book_entries: {
          entry_details: {}, //Dettaglio(Oggetto)
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseDatepicker,
    BaseInput,
    BaseSelect,
    BaseCurrency,
    BaseLabel,
    EntryDetails,
    QuickSearchRegistryModal,
  },
  methods: {
    toLocaleCurrency,
    toInfoData,

    /* CREATE + EDIT Method */
    openQuickSearchRegistry() {
      this.form.registry = null; // the form v-model
      this.$bvModal.show("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label = null;
      this.form.registry = null;
    },
    handleRegistryModalInput(value) {
      this.form.registry = value.id; // the form v-model
      this.registry_data = value; // the whole record data
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onComputeBrokerStatementMode(value) {
      // 1134: Dan dice di lasciare visibile il valore "ingrigito", basta poi non salvarlo (rimuovere l'evento @change)
      if (value) {
        this.form[this.rep].saler_fee_take = 0.0;
      }
    },
    onInputNet() {
      this.form[this.rep].gross =
        this.form[this.rep].net + this.form[this.rep].tax;
    },
    onInputTax() {
      this.form[this.rep].gross =
        this.form[this.rep].net + this.form[this.rep].tax;
    },
    onInputGross() {
      if (this.form[this.rep].net === 0 && this.form[this.rep].tax !== 0) {
        this.form[this.rep].net =
          this.form[this.rep].gross - this.form[this.rep].tax;
      }
      this.form[this.rep].tax =
        this.form[this.rep].gross - this.form[this.rep].net;
    },
    onInputNetDiritti() {
      this.form[this.rep].fee_gross =
        this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
    },
    onInputTaxDiritti() {
      this.form[this.rep].fee_gross =
        this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
    },
    onInputGrossDiritti() {
      if (
        this.form[this.rep].fee_net === 0 &&
        this.form[this.rep].fee_tax !== 0
      ) {
        this.form[this.rep].fee_net =
          this.form[this.rep].fee_gross - this.form[this.rep].fee_tax;
      }
      this.form[this.rep].fee_tax =
        this.form[this.rep].fee_gross - this.form[this.rep].fee_net;
    },
    onNetBlur() {
      // console.log(event);
      if (!this.agency_broker_id || !this.form[this.rep].insurer_id) {
        return;
      }
      this.isLoadingSpecifications = true;
      const Repo = RepositoryFactory.get("commission");
      const risk_branch_id = this.$store.state.auth.products.find(
        (e) => e.id == this.insurance_risk_id
      ).risk_branch_id;
      let purchase_commission;
      let take_commission;
      Repo.specifications(
        this.agency_broker_id,
        this.form[this.rep].insurer_id,
        risk_branch_id
      )
        .then((response) => {
          let found = response.data.data.find(
            (e) => e.id === this.insurance_risk_id
          );
          if (found) {
            if (found.commissions.length) {
              let commissions = found.commissions.find(
                (e) => e.broker_id === this.agency_broker_id
              );
              if (commissions) {
                purchase_commission = commissions.purchase_commission;
                take_commission = commissions.take_commission;
                console.log(purchase_commission);
                console.log(take_commission);
                this.setSpecifications(purchase_commission, take_commission);
                this.isLoadingSpecifications = false;
              } else {
                console.log(
                  "no commission found for broker: ",
                  this.agency_broker_id
                );
                this.isLoadingSpecifications = false;
              }
            } else {
              // non trovato, sale di livello
              Repo.specifications(
                this.agency_broker_id,
                this.form[this.rep].insurer_id
              )
                .then((response) => {
                  let found = response.data.data.find(
                    (e) => e.id === risk_branch_id
                  );
                  if (found) {
                    if (found.commissions.length) {
                      let commissions = found.commissions.find(
                        (e) => e.broker_id === this.agency_broker_id
                      );
                      if (commissions) {
                        purchase_commission = commissions.purchase_commission;
                        take_commission = commissions.take_commission;
                        console.log(purchase_commission);
                        console.log(take_commission);
                        this.setSpecifications(
                          purchase_commission,
                          take_commission
                        );
                        this.isLoadingSpecifications = false;
                      } else {
                        console.log(
                          "no commission found for broker: ",
                          this.agency_broker_id
                        );
                        this.isLoadingSpecifications = false;
                      }
                    } else {
                      // nothing to do
                      console.log("no commissions found");
                      this.isLoadingSpecifications = false;
                    }
                  } else {
                    // non dovrebbe mai entrare qui
                    console.error("not found risk branch: ", risk_branch_id);
                    this.isLoadingSpecifications = false;
                  }
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: errMsg,
                  });
                  this.isLoadingSpecifications = false;
                });
              // .finally(() => {
              //   this.isLoadingSpecifications = false;
              // });
            }
          } else {
            // non dovrebbe mai entrare qui
            console.error("not found insurance risk: ", this.insurance_risk_id);
            this.isLoadingSpecifications = false;
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
          this.isLoadingSpecifications = false;
        });
      // .finally(() => {
      //   this.isLoadingSpecifications = false;
      // });
    },
    setSpecifications(purchase_commission, take_commission) {
      let p = parseFloat(
        (
          (parseFloat(this.form[this.rep].net) *
            parseFloat(purchase_commission)) /
          100
        ).toFixed(2)
      );
      let c = parseFloat(
        (
          (parseFloat(this.form[this.rep].net) * parseFloat(take_commission)) /
          100
        ).toFixed(2)
      );
      this.$set(this.form[this.rep], "agency_prov_purchase", p);
      this.$set(this.form[this.rep], "agency_prov_take", c);
    },
    resetSpecifications() {
      this.$set(this.form[this.rep], "agency_prov_purchase", 0);
      this.$set(this.form[this.rep], "agency_prov_take", 0);
    },
    /* CREATE + EDIT Method */

    /* CREATE + EDIT Method (after merge) */
    grossRow(id) {
      //Gross Row Sum
      if (this.type === "create") {
        let rowNet = this.bookEntryBreakdowns.find((el) => el.id == id).net
          .value;
        let rowTax = this.bookEntryBreakdowns.find((el) => el.id == id).tax
          .value;
        return toLocaleCurrency(rowNet + rowTax);
      }
    },
    grossRowBookEntry(net, tax) {
      return toLocaleCurrency(net + tax);
    },
    agencyTakeRow(id) {
      return toLocaleCurrency(
        this.agencyProvBreakdowns.find((el) => el.id == id).take.value
      );
    },
    agencyPurchaseRow(id) {
      return toLocaleCurrency(
        this.agencyProvBreakdowns.find((el) => el.id == id).purchase.value
      );
    },
    closeAllSections() {
      //Close All Sections
      this.isBookEntryBreakdown = false;
      this.isAgencyBreakdown = false;
      this.isSalerBreakdown = false;
    },
    onClickSwitchBookEntry() {
      //Handle switch (click)
      if (!this.insurance_risk_id) {
        this.$showSnackbar({
          preset: "error",
          text: `Devi selezionare un prodotto per abilitare gli scorpori`,
        });
      }
      this.deleteAll();
    },
    onChangeSwitchBookEntry() {
      //Handle switch (change)
      //Clear premium v-model()
      if (!this.isBookEntryBreakdown) {
        this.isAgencyBreakdown = false;
        this.isSalerBreakdown = false;
      }
      this.clearPremiumVModel();
    },
    clearPremiumVModel() {
      //Handle v-model refresh
      if (!this.isBookEntryBreakdown) {
        this.form[this.rep].tax = 0.0;
        this.form[this.rep].net = 0.0;
      }
    },
    /* CREATE + EDIT Method (after merge) */

    /* CREATE + EDIT Mixed Method */
    onInputInsurer(insurer) {
      //Multiselect input handlers
      // se nessuno  è selezionato, svuoto i rami e prodotti
      this.form[this.rep].insurer_id = insurer;
      // deseleziono
      this.risk_branch_id = null;
      // e ricarico i branch
      this.risk_branches = this.getBranches()(insurer);
      // deseleziono
      this.insurance_risk_id = null;
      // e ricarico i prodotti
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.risk_branch_id,
        this.risk_type_id
      );
      // reset Codice Mandato
      this.form[this.rep].mandate_code_id = null;
      // carica (o svuota) la tendina dei mandati
      this.mandate_code_options = [];
      if (insurer) {
        this.mandate_code_options = this.getMandateCodes()(insurer);
        const selectedInsurer = this.getInsurers().find(
          (e) => e.value == insurer
        );
        if (selectedInsurer && selectedInsurer.use_fee === "N") {
          this.form[this.rep].fee_tax = 0.0;
          this.form[this.rep].fee_net = 0.0;
          this.form[this.rep].saler_fee_take = 0.0;
          this.insurerHasFee = false;
        }
        if (selectedInsurer && selectedInsurer.use_fee === "Y") {
          this.insurerHasFee = true;
        }
      } else {
        this.form[this.rep].fee_tax = 0.0;
        this.form[this.rep].fee_net = 0.0;
        this.form[this.rep].saler_fee_take = 0.0;
        this.insurerHasFee = false;
      }

      if (!this.form[this.rep].insurance_risk_id) {
        this.closeAllSections();
      }
    },
    onInputBranch(branches) {
      // se nessuno  è selezionato, svuoto i prodotti? NO! li ricarico
      // if (!branches.length) {
      //   // deseleziono...
      //   this.risk_branch_id = null;
      // }
      // deseleziono
      this.insurance_risk_id = null;
      // e ricarico i prodotti
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        branches,
        this.risk_type_id
      );

      if (this.type === "create" && !this.form[this.rep].insurance_risk_id) {
        this.closeAllSections();
      }
    },
    onSelectProduct(value) {
      // If insurance_risk_id is NOT null
      // clear net
      this.form[this.rep].net = 0.0;
      //if (this.type === "edit") {
      this.form[this.rep].tax = 0.0;
      //}

      // Clear Diritti
      if (!this.isBookEntryBreakdown) {
        this.form[this.rep].fee_net = 0.0;
        this.form[this.rep].fee_tax = 0.0;
      }

      // Reset Provvigioni di agenzia
      this.resetSpecifications();

      // Reset Provvigioni produttore
      this.form[this.rep].saler_prov_purchase = 0.0;
      this.form[this.rep].saler_prov_take = 0.0;
      this.form[this.rep].saler_fee_take = 0.0;
      if (this.type === "create") this.computeBrokerStatementMode = true;

      // Reset righe di pagamento
      this.deleteAll();

      // if (this.type === "create" && value) {
      this.isLoadingBreakdowns = true;
      const Repo = RepositoryFactory.get("breakdown");
      // let querystring = `byAttribute[insurance_risk_id]=${value}`;
      let querystring = `byInsuranceRisk[id]=${value}`;
      Repo.index(querystring)
        .then((response) => {
          const data = response.data.data;
          if (data.length > 0) {
            //isEntryBookBreakdown Data
            this.bookEntryBreakdowns = dataMappingPremium(data);
            //isSalesBreakdown Data
            this.salerProvBreakdowns = dataMappingProv(data);
            //isAgencyBreakdown Data
            this.agencyProvBreakdowns = dataMappingProv(data);
          } else {
            this.bookEntryBreakdowns = [];
            this.salerProvBreakdowns = [];
            this.agencyProvBreakdowns = [];
            this.closeAllSections();
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoadingBreakdowns = false;
        });
      // }
    },
    onDeleteProduct() {
      // clear net
      this.form[this.rep].net = 0;
      if (this.type === "edit") {
        this.form[this.rep].tax = 0;
      }
      this.resetSpecifications();
      if (this.type === "create" && this.isBookEntryBreakdown) {
        this.clearPremiumVModel();
      }
      if (
        this.type === "create" &&
        (this.isAgencyBreakdown ||
          this.isSalerBreakdown ||
          this.isBookEntryBreakdown)
      ) {
        this.closeAllSections();
      }
      this.insurance_risk_id = null;
    },
    deleteAll() {
      if (this.type === "create") {
        for (let d of this.dtls.filter((dtl) => dtl.selected)) {
          this.deleteCreateEntryDetail(
            this.form.book_entries.entry_details,
            d.id
          );
        }
      } else {
        for (const d in this.dtlscfg) {
          this.deleteEditEntryDetail(this.form.book_entries.entry_details, d);
        }
      }
    },
    onSubmit() {
      if (this.type === "create") {
        const val = this.isBookEntryBreakdown
          ? sumBreakdowns(this.bookEntryBreakdowns, "net") +
            sumBreakdowns(this.bookEntryBreakdowns, "tax") +
            (this.agencyHasFee && this.insurerHasFee ? this.grossSumFee : 0)
          : this.agencyHasFee && this.insurerHasFee
          ? this.grandGross
          : this.grss;
        if (val != this.entryDetailsCreateTotal) {
          this.$showSnackbar({
            preset: "error",
            text: `La somma delle forme di pagamento deve essere uguale al lordo`,
          });
          return;
        }
        if (this.checkEntryDetails()) {
          this.$showSnackbar({
            preset: "error",
            text: `Non è possibile aggiungere un abbuono in presenza di un sospeso`,
          });
          return;
        }
        // Se il gross è positivo , non si può utilizzare un acconto
        if (this.checkGrossAdvancePayment()) {
          this.$showSnackbar({
            preset: "error",
            text: `Non è possibile aggiungere un acconto in presenza di un importo lordo positivo`,
          });
          return;
        }
        // //Book_entry form
        if (this.isBookEntryBreakdown) {
          this.form[this.rep].various_accounting = {
            1: { insurance_risk_id: this.insurance_risk_id },
          };
          this.form[this.rep].various_accounting[1].insurance_policy_number =
            this.form[this.rep].insurance_policy_number
              ? this.form[this.rep].insurance_policy_number
              : null;
          this.form[this.rep].net = sumBreakdowns(
            this.bookEntryBreakdowns,
            "net"
          );
          this.form[this.rep].gross =
            sumBreakdowns(this.bookEntryBreakdowns, "net") +
            sumBreakdowns(this.bookEntryBreakdowns, "tax");
          this.form[this.rep].tax = sumBreakdowns(
            this.bookEntryBreakdowns,
            "tax"
          );
        } else {
          // aggiungo se non ho scorpori selezionai ma c'è il prodotto
          this.form[this.rep].various_accounting = {
            1: this.insurance_risk_id
              ? { insurance_risk_id: this.insurance_risk_id }
              : {},
          };
          this.form[this.rep].various_accounting[1].insurance_policy_number =
            this.form[this.rep].insurance_policy_number
              ? this.form[this.rep].insurance_policy_number
              : null;
          this.form[this.rep].gross = this.form[this.rep].gross
            ? this.form[this.rep].gross
            : 0.0;
          //Gross
          this.form[this.rep].gross =
            this.agencyHasFee && this.insurerHasFee
              ? this.grandGross
              : this.grss;
        }
        //Agency Form
        if (this.isAgencyBreakdown) {
          this.form[this.rep].agency_prov_purchase = sumBreakdowns(
            this.agencyProvBreakdowns,
            "purchase"
          );
          this.form[this.rep].agency_prov_take = sumBreakdowns(
            this.agencyProvBreakdowns,
            "take"
          );
        } else {
          //Agency
          this.form[this.rep].agency_prov_purchase = this.form[this.rep]
            .agency_prov_purchase
            ? this.form[this.rep].agency_prov_purchase
            : 0.0;
          this.form[this.rep].agency_prov_take = this.form[this.rep]
            .agency_prov_take
            ? this.form[this.rep].agency_prov_take
            : 0.0;
        }
        //Sales Form
        if (this.isSalerBreakdown) {
          this.form[this.rep].saler_prov_purchase = sumBreakdowns(
            this.salerProvBreakdowns,
            "purchase"
          );
          this.form[this.rep].saler_prov_take = sumBreakdowns(
            this.salerProvBreakdowns,
            "take"
          );
        } else {
          this.form[this.rep].saler_prov_purchase = this.form[this.rep]
            .saler_prov_purchase
            ? this.form[this.rep].saler_prov_purchase
            : 0.0;
          this.form[this.rep].saler_prov_take = this.form[this.rep]
            .saler_prov_take
            ? this.form[this.rep].saler_prov_take
            : 0.0;
        }

        //this.form[this.rep].effective_date = this.form[this.rep].book_date;
        this.form[this.rep].fee_gross =
          this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
        this.form[this.rep].registry = this.form.registry
          ? { [this.form.registry]: {} }
          : null;
        this.form[this.rep].broker = this.form.broker
          ? { [this.form.broker]: {} }
          : null;

        this.isLoading = true;

        if (this.computeBrokerStatementMode) {
          // this.form[this.rep].saler_prov_purchase = null;
          // this.form[this.rep].saler_prov_take = null;
          // 1134: DAN se disabilitato azzeriamo, lo scopo è quello
          // al salvataggio devo passare per forza "0.0", perché se passo "null" il campo NON viene inserito nel payload
          // chiamare la store direttamente dal Repo.store per bypassare l'epurazione dei null (removeEmpty)
          this.form[this.rep].saler_prov_purchase = null;
          this.form[this.rep].saler_prov_take = null;
          this.form[this.rep].saler_fee_take = 0.0;
        }

        //Book entry store
        this.isLoading = true;
        this.store(this.repository)
          .then((response) => {
            let storetreasuries = [];
            let storebooktags = [];
            let id = response.data.data.id;
            //Add BookTags
            if (this.form.book_tag) {
              storebooktags.push(
                this.addBookTag(id, "book_tag", {
                  book_tag: { [this.form.book_tag]: {} },
                })
              );
            }
            //Add EntryDetails
            if (this.form.book_entries.entry_details) {
              this.form[this.rep_entrdtl].book_entry_id = response.data.data.id;
              this.form[this.rep_entrdtl].detail_date =
                this.form[this.rep].book_date;
              //"Casse Statiche"
              let ENid = this.$store.state.auth.treasuries.find(
                (el) => el.code === "EN"
              ).id;
              let PRid = this.$store.state.auth.treasuries.find(
                (el) => el.code === "PR"
              ).id;
              if (ENid && PRid) {
                let bodyEN = {
                  treasury_id: ENid,
                  gross: this.form[this.rep].gross,
                };
                let bodyPR = {
                  treasury_id: PRid,
                  gross:
                    this.form[this.rep].agency_prov_take +
                    this.form[this.rep].agency_prov_purchase,
                };
                [bodyEN, bodyPR].forEach((body) => {
                  this.form[this.rep_entrdtl].gross = body.gross;
                  this.form[this.rep_entrdtl].treasury_id = body.treasury_id;
                  let staticTrs = this.store(this.rep_entrdtl);
                  storetreasuries.push(staticTrs);
                });
              }
              //Entry Detail
              Object.values(this.form.book_entries.entry_details)
                .filter(
                  (treasury) => treasury.type !== null && treasury.type !== null
                )
                .forEach((filteredTreasury) => {
                  this.form[this.rep_entrdtl].gross = filteredTreasury.amount;
                  this.form[this.rep_entrdtl].treasury_id =
                    filteredTreasury.type;
                  let trs = this.store(this.rep_entrdtl);
                  storetreasuries.push(trs);
                });
            }
            //Add breakdowns
            if (this.isBookEntryBreakdown) {
              for (let i = 0; i < this.bookEntryBreakdowns.length; i++) {
                let breakdownId = this.bookEntryBreakdowns[i].id;
                if (!this.isAgencyBreakdown && !this.SalerBreakdown) {
                  this.form.breakdown[breakdownId] = {
                    net: this.bookEntryBreakdowns[i].net.value,
                    tax: this.bookEntryBreakdowns[i].tax.value,
                  };
                }
                if (this.isAgencyBreakdown && this.isSalerBreakdown) {
                  this.form.breakdown[breakdownId] = {
                    net: this.bookEntryBreakdowns[i].net.value,
                    tax: this.bookEntryBreakdowns[i].tax.value,
                    agency_prov_take: this.agencyProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).take.value,
                    agency_prov_purchase: this.agencyProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).purchase.value,
                    saler_prov_take: this.salerProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).take.value,
                    saler_prov_purchase: this.salerProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).purchase.value,
                  };
                }
                if (this.isAgencyBreakdown && !this.isSalerBreakdown) {
                  this.form.breakdown[breakdownId] = {
                    net: this.bookEntryBreakdowns[i].net.value,
                    tax: this.bookEntryBreakdowns[i].tax.value,
                    agency_prov_take: this.agencyProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).take.value,
                    agency_prov_purchase: this.agencyProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).purchase.value,
                  };
                }
                if (!this.isAgencyBreakdown && this.SalerBreakdown) {
                  this.form.breakdown[breakdownId] = {
                    net: this.bookEntryBreakdowns[i].net.value,
                    tax: this.bookEntryBreakdowns[i].tax.value,
                    saler_prov_take: this.salerProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).take.value,
                    saler_prov_purchase: this.salerProvBreakdowns.find(
                      (brk) => brk.id === breakdownId
                    ).purchase.value,
                  };
                }
              }
              let storebreakdownables = this.addBreakdownables(
                id,
                "breakdown",
                {
                  breakdown: this.form.breakdown,
                }
              );
              Promise.all([
                ...storetreasuries,
                ...storebooktags,
                storebreakdownables, // non è un array...
              ])
                .then(() => {
                  this.completionMessage();
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                  this.isLoading = false;
                });
            } else {
              Promise.all([...storebooktags, ...storetreasuries])
                .then(() => {
                  this.completionMessage();
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                  this.isLoading = false;
                });
            }
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
        // }
      } else {
        const val = this.isBookEntryBreakdown
          ? sumBreakdowns(this.bookEntryBreakdowns, "net") +
            sumBreakdowns(this.bookEntryBreakdowns, "tax") +
            (this.agencyHasFee && this.insurerHasFee ? this.grossSumFee : 0)
          : this.agencyHasFee && this.insurerHasFee
          ? this.grandGross
          : this.grss;
        if (!this.isInsurerAccounting && val != this.entryDetailsEditTotal) {
          this.$showSnackbar({
            preset: "error",
            text: `La somma delle forme di pagamento deve essere uguale al lordo`,
          });
          return;
        }
        if (
          this.hasEntryDetailByCode("SS") &&
          this.hasEntryDetailByCode("AB")
        ) {
          this.$showSnackbar({
            preset: "error",
            text: `Non è possibile utilizzare insieme le forme di pagamento abbuono e sospeso`,
          });
          return;
        }

        this.setFormValues();

        if (!this.isBookEntryBreakdown) {
          //Gross
          this.form[this.rep].gross =
            this.agencyHasFee && this.insurerHasFee
              ? this.grandGross
              : this.grss;
          this.form[this.rep].fee_gross =
            this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
        }

        // chiamare la store direttamente dal Repo.store per bypassare l'epurazione dei null (removeEmpty)
        const Repo = RepositoryFactory.get(this.repository);
        // this.update(this.repository, this.id)
        Repo.update(this.id, this.form[this.rep])
          .then(() => {
            const storeBookTags = this.updateBookTag();
            const storeEntryDetails = this.canEdit
              ? this.submitEntryDetailsPromise()
              : [];
            // Promise.all([storeBookTags, storeEntryDetails])
            Promise.all([...storeBookTags, ...storeEntryDetails])
              .then(() => {
                this.onSuccess();
              })
              .catch((error) => {
                this.onFailure(error);
              });
          })
          .catch((error) => {
            this.onFailure(error);
          });
      }
    },
    /* CREATE + EDIT Mixed Method */

    /* CREATE Method */
    initDefaultFormValues() {
      if (this.type === "create") {
        this.form.book_entry.is_taxable = "Y";
      }
    },
    byRemainderFunctionBookEntry() {
      if (this.isBookEntryBreakdown) {
        return (
          sumBreakdowns(this.bookEntryBreakdowns, "net") +
          sumBreakdowns(this.bookEntryBreakdowns, "tax") +
          (this.agencyHasFee && this.insurerHasFee ? this.grossSumFee : 0)
        );
      } else {
        return this.agencyHasFee && this.insurerHasFee
          ? this.grandGross
          : this.grss;
      }
    },
    customResetForm() {
      if (this.type === "create") {
        this.deleteAll();
        this.resetForm();
      }
    },
    /* CREATE - onSubmit */
    checkGrossAdvancePayment() {
      // Card 650
      if (this.type === "create") {
        const entryDetailsCodes = this.dtls
          .filter((dtl) => dtl.selected)
          .map((dtl) => {
            return {
              code: dtl.options.filter(
                (option) => option.value === dtl.selected
              )[0].code,
            };
          });

        const checkAC = entryDetailsCodes.find((code) => code.code === "AC");
        if (checkAC && this.grss >= 0) {
          return true;
        }
        return false;
      }
    },
    checkEntryDetails() {
      if (this.type === "create") {
        const entryDetailsCodes = this.dtls
          .filter((dtl) => dtl.selected)
          .map((dtl) => {
            return {
              code: dtl.options.filter(
                (option) => option.value === dtl.selected
              )[0].code,
            };
          });

        const checkSS = entryDetailsCodes.find((code) => code.code === "SS");
        const checkAB = entryDetailsCodes.find((code) => code.code === "AB");
        if (entryDetailsCodes.length === 2 && checkSS && checkAB) {
          return true;
        }
        return false;
      }
    },
    completionMessage() {
      if (this.type === "create") {
        this.$showSnackbar({
          preset: "success",
          text: `Azione Completata: Movimento Contabile Creato (I)`,
        });
        this.shortcut(
          "module.CONT.FCAS",
          null,
          null,
          "filterVariousAccounting"
        );
      }
    },
    addBookTag(bookEntryId, relation, payload) {
      if (this.type === "create") {
        const Repo = RepositoryFactory.get("book_entry");
        return Repo.pivot_store(bookEntryId, relation, payload);
      }
    },
    addBreakdownables(bookEntryId, relation, payload) {
      if (this.type === "create") {
        const Repo = RepositoryFactory.get("book_entry");
        return Repo.pivot_store(bookEntryId, relation, payload);
      }
    },
    /* CREATE - onSubmit */

    grossRowAncillaries(id) {
      //Breakdowns Component
      if (this.type === "create") {
        let rowNet = this.insuranceAncillariesBreakdowns.breakdowns.find(
          (el) => el.id == id
        ).pivot.net;
        let rowTax = this.insuranceAncillariesBreakdowns.breakdowns.find(
          (el) => el.id == id
        ).pivot.tax;
        return toLocaleCurrency(rowNet + rowTax);
      }
    },
    agencyTakeRowAncillaries(id) {
      if (this.type === "create") {
        return toLocaleCurrency(
          this.insuranceAncillariesAgencyBreakdowns.breakdowns.find(
            (el) => el.id == id
          ).pivot.take
        );
      }
    },
    agencyPurchaseRowAncillaries(id) {
      if (this.type === "create") {
        return toLocaleCurrency(
          this.insuranceAncillariesAgencyBreakdowns.breakdowns.find(
            (el) => el.id == id
          ).pivot.purchase
        );
      }
    },
    onClickSwitchSaler() {
      if (this.type === "create") {
        if (!this.isBookEntryBreakdown) {
          this.$showSnackbar({
            preset: "error",
            text: `Devi abilitare gli scorpori del Premio`,
          });
        }
      }
    },
    onChangeSwitchSaler() {
      if (this.type === "create") {
        this.net_saler_take = 0.0;
        this.net_saler_purchase = 0.0;
        this.tax_saler_take = 0.0;
        this.tax_saler_purchase = 0.0;
        this.form[this.rep].saler_prov_purchase = null;
        this.form[this.rep].saler_prov_take = null;
        if (this.isSalerBreakdown) {
          this.computeBrokerStatementMode = false;
        }
      }
    },
    /* */
    onClickSwitchAgency() {
      if (this.type === "create") {
        if (!this.isBookEntryBreakdown) {
          this.$showSnackbar({
            preset: "error",
            text: `Devi abilitare gli scorpori del Premio`,
          });
        }
      }
    },
    onChangeSwitchAgency() {
      if (this.type === "create") {
        this.net_agency_take = 0.0;
        this.net_agency_purchase = 0.0;
        this.tax_agency_take = 0.0;
        this.tax_agency_purchase = 0.0;
        this.form[this.rep].agency_prov_purchase = 0.0;
        this.form[this.rep].agency_prov_take = 0.0;
      }
    },
    /* */
    /* Unused ? */
    reduceSum(array, field) {
      //Sum
      if (this.type === "create") {
        let initialValue = 0;
        let sum = array.reduce(function (previousValue, currentValue) {
          return previousValue + currentValue[field].value;
        }, initialValue);
        return sum;
      }
    },
    /* CREATE Method */

    /* EDIT Method */
    initDefaultValues() {
      if (this.type === "edit") {
        // Compagnia
        this.onInputInsurer(this.form[this.rep].insurer_id);
        //Prodotto
        this.insurance_risk_id = this.isVariousAccounting
          ? this.item.various_accountings[0].pivot?.insurance_risk?.id
          : this.isInsuranceAncillaries
          ? this.item.insurance_ancillaries[0].pivot?.insurance_risk?.id
          : null;
        //  this.item.various_accountings?.[0].pivot?.insurance_risk.id;
        // Rischio
        this.risks = this.getRisks();
        this.risk_type_id = this.isVariousAccounting
          ? this.item.various_accountings[0].pivot?.insurance_risk?.risk_branch
              ?.risk_type?.id
          : null;
        // Ramo
        this.risk_branch_id = this.isVariousAccounting
          ? this.item.various_accountings[0].pivot?.insurance_risk?.risk_branch
              ?.id
          : this.isInsuranceAncillaries
          ? this.item.insurance_ancillaries[0].pivot?.insurance_risk
              ?.risk_branch?.id
          : null;
        // Codice Mandato
        this.form[this.rep].mandate_code_id = this.item.mandate_code_id;
        // Calcola in fase rendicontale utilizzando il capitolato provvigionale
        if (
          this.item.saler_prov_purchase === null &&
          this.item.saler_prov_take === null
        ) {
          this.computeBrokerStatementMode = true;
        }
        // Enable treasuries
        // this.onlyRealTreasuries = true;
        this.editEntryDetailsCompToggle = true;
        // Anagrafica
        this.registry_data = this.item.registries.length
          ? this.item.registries[0]
          : null;
        if (this.registry_data) {
          this.form.registry = this.registry_data.id;
          this.form.inpt_label = this.toInfoData(
            this.registry_data,
            "registryfullname"
          );
        }
        // Numero Polizza
        this.form[this.rep].insurance_policy_number =
          this.item.various_accountings && this.item.various_accountings.length
            ? this.item.various_accountings[0].pivot.insurance_policy_number
            : this.item.insurance_ancillaries &&
              this.item.insurance_ancillaries.length
            ? this.item.insurance_ancillaries[0].insurance_policy
              ? this.item.insurance_ancillaries[0].insurance_policy.number
              : null
            : null;
        // Compagnie
        this.companies = this.getInsurers();
        // Produttori
        this.broker_options = this.getSalesmen();
        this.form.broker = this.item.brokers.length
          ? this.item.brokers[0].id
          : null;
        // Codici Documento
        this.book_tags_opt = this.getBookTags();
        this.form.book_tag = this.item.book_tags.length
          ? this.item.book_tags[0].id
          : null;
        // # 1134
        this.agency_broker_id = this.getAgencyBroker()[0]?.value;
      }
    },
    setEntryDetails() {
      if (this.type === "edit") {
        let skipCodes = [
          this.REVENUE,
          this.COMMISSIONS,
          this.COASS_COMMISSIONS,
          this.COASS_PREMIO,
        ];
        this.item.entry_details.sort((a, b) =>
          a.entry_time.localeCompare(b.entry_time)
        );

        //Check if treasury relation exists (card 646)
        this.checkTreasuryRelation = this.item.entry_details.filter(
          (e) => e.treasury
        );
        // List of negative deferred, if any
        const allNegativeDeferred = this.checkTreasuryRelation.filter(
          (e) => e.treasury.code === this.DEFERRED && e.gross < 0
        );
        // List of negative advance payment, if any
        const allNegativeAdvancePayment = this.checkTreasuryRelation.filter(
          (e) => e.treasury.code === this.ADVANCE_PAYMENT && e.gross < 0
        );

        if (this.hasTreasuryRelation) {
          let skipIds = []; // list if ids to exclude since they have a pivot relation...
          for (const d of allNegativeDeferred) {
            skipIds.push(d.id); // add itself...
            skipIds = skipIds.concat(d.entry_details.map((e) => e.id)); // add the pivot related ones...
          }
          for (const d of allNegativeAdvancePayment) {
            skipIds.push(d.id); // add itself...
            skipIds = skipIds.concat(d.entry_details.map((e) => e.id)); // add the pivot related ones...
          }

          const filteredEntryDetails = this.item.entry_details.filter(
            (e) =>
              !skipIds.includes(e.id) &&
              !skipCodes.includes(e.treasury.code) &&
              e.detail_date === this.item.book_date &&
              !e.entry_details.length
          );
          this.entryDetails = filteredEntryDetails;
          this.changeEntryDetails(this.form.book_entries.entry_details);
        }

        if (
          this.atLeastOneTreasuryByCode(this.DEFERRED) ||
          this.atLeastOneTreasuryByCode(this.ADVANCE_PAYMENT) ||
          !this.hasTreasuryRelation ||
          !this.hasEntryDetails ||
          this.isConsolidated
        ) {
          this.canEdit = false;
        } else {
          this.canEdit = true;
        }
      }
    },
    setFormValues() {
      if (this.type === "edit") {
        //this.form[this.rep].effective_date = this.form[this.rep].book_date;
        if (this.computeBrokerStatementMode) {
          // this.form[this.rep].saler_prov_purchase = null;
          // this.form[this.rep].saler_prov_take = null;
          // 1134: DAN se disabilitato azzeriamo, lo scopo è quello
          // al salvataggio devo passare per forza "0.0", perché se passo "null" il campo NON viene inserito nel payload
          // chiamare la store direttamente dal Repo.store per bypassare l'epurazione dei null (removeEmpty)
          this.form[this.rep].saler_prov_purchase = null;
          this.form[this.rep].saler_prov_take = null;
          this.form[this.rep].saler_fee_take = null;
        }
        // Anagrafica
        this.form[this.rep].registry = this.form.registry
          ? { [this.form.registry]: {} }
          : {};
        // Produttore
        this.form[this.rep].broker = this.form.broker
          ? { [this.form.broker]: {} }
          : {};
        // Tipologia di movimento Contabile
        if (this.isVariousAccounting) {
          const book_entry_id = this.item.various_accountings[0].id;
          this.form[this.rep]["various_accounting"] = {
            [book_entry_id]: {
              insurance_risk_id:
                // this.item.various_accountings[0].pivot.insurance_risk_id,
                this.insurance_risk_id,
              // #1134
              insurance_policy_number:
                this.form[this.rep].insurance_policy_number,
            },
          };
        }
        if (
          this.item.insurance_ancillaries &&
          this.item.insurance_ancillaries.length
        ) {
          const insurance_ancillary_id = this.item.insurance_ancillaries[0].id;
          this.form[this.rep]["insurance_ancillary"] = {
            [insurance_ancillary_id]: {},
          };
        }
      }
    },
    fetch() {
      if (this.type === "edit") {
        const Repo = RepositoryFactory.get("consolidated_treasury");
        const queryString = `byCalendar=${this.item.book_date},${this.item.book_date}`;
        Repo.index(queryString)
          .then((response) => {
            this.consolidated_data = response.data;
            const ed = this.fetchCreateForm("entry_detail");
            const be = this.fetchEditForm(this.repository, this.id);
            Promise.all([ed, be])
              .then(() => {
                this.initDefaultValues();
                this.setEntryDetails();
                this.isLoading = false;
              })
              .catch((error) => {
                this.onFailure(error);
              });
          })
          .catch((error) => {
            this.onFailure(error);
          });
      }
    },
    updateBookTag() {
      if (this.type === "edit") {
        let ret = [];
        if (this.form.book_tag) {
          ret.push(
            this.editBookTag(this.id, "book_tag", {
              book_tag: { [this.form.book_tag]: {} },
            })
          );
        }
        return ret;
      }
    },
    editBookTag(bookEntryId, relation, payload) {
      if (this.type === "edit") {
        const Repo = RepositoryFactory.get("book_entry");
        return Repo.pivot_update(bookEntryId, relation, payload);
      }
    },
    onAdvancePayment() {
      if (this.type === "edit") {
        // NOTA: rispetto a toRebate(), qui siano nel contesto di edit e non di create
        let t_id = this.$store.state.auth.treasuries.find(
          (treasury) => treasury.code === "AC"
        ).id;
        const key = Object.keys(this.form.book_entries.entry_details).find(
          (k) => this.form.book_entries.entry_details[k].type === t_id
        );
        // pulisco eventuali NewIndex con type e amount null
        for (const [key, value] of Object.entries(
          this.form.book_entries.entry_details
        )) {
          if (key.toString().startsWith("NewIndex") && !value.type) {
            delete this.form.book_entries.entry_details[key];
          }
        }

        if (!key) {
          // se ancora non c'è nel form, lo aggiungo usando NewIndex-index...
          let index =
            Object.keys(this.form.book_entries.entry_details).filter((e) =>
              e.toString().startsWith("NewIndex")
            ).length + 1;
          this.$set(
            this.form.book_entries.entry_details,
            `NewIndex-${index}`,
            {}
          );
          this.$set(
            this.form.book_entries.entry_details[`NewIndex-${index}`],
            "type",
            t_id
          );
          this.$set(
            this.form.book_entries.entry_details[`NewIndex-${index}`],
            "amount",
            this.entryDetailsCreateTotal() - this.details.gross
          );

          this.selectCreateEntryDetail(
            this.form.book_entries.entry_details,
            t_id,
            index
          );
        } else {
          // ...altrimenti, modifico l'importo
          this.form.book_entries.entry_details[key].amount =
            this.entryDetailsCreateTotal() - this.details.gross;
        }
      }
    },
    onSuccess() {
      if (this.type === "edit") {
        this.$showSnackbar({
          preset: "success",
          text: `Azione Completata: Movimento Contabile Modificato (I)`,
        });
        this.shortcut(
          "module.CONT.FCAS",
          null,
          null,
          "filterVariousAccounting"
        );
      }
    },
    onFailure(error) {
      if (this.type === "edit") {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
      }
    },
    onInputRisk(risks) {
      if (this.type === "edit") {
        // se nessuno è selezionato, svuoto i prodotti? NO! li ricarico
        if (!risks.length) {
          // deseleziono...
          this.risk_type_id = null;
        }
        // deseleziono
        this.insurance_risk_id = null;
        // e ricarico i prodotti
        this.products = this.getProducts()(
          this.form[this.rep].insurer_id,
          this.risk_branch_id,
          risks
        );

        if (!this.form[this.rep].insurance_risk_id) {
          this.closeAllSections();
        }
      }
    },
    submitEntryDetailsPromise() {
      if (this.type === "edit") {
        let storedtls = [];
        // salvare con un entry_time incrementale i NewIndex
        let ut = moment().unix();
        for (const key in this.dtlscfg) {
          if (isNaN(parseInt(key))) {
            if (
              this.form.book_entries.entry_details[key].type &&
              this.form.book_entries.entry_details[key].amount
            ) {
              // sto creando, imposto entry_time
              this.$set(
                this.form[this.rep_entrdtl],
                "entry_time",
                moment(ut).format("x")
              );
              ut += 1;
              this.form[this.rep_entrdtl].book_entry_id = this.id;
              this.form[this.rep_entrdtl].gross =
                this.form.book_entries.entry_details[key].amount;
              this.form[this.rep_entrdtl].treasury_id =
                this.form.book_entries.entry_details[key].type;
              this.form[this.rep_entrdtl].detail_date =
                this.form.book_entry.book_date;
              let sdtls = this.store(this.rep_entrdtl);
              storedtls.push(sdtls);
            }
          } else {
            this.form[this.rep_entrdtl].book_entry_id = this.id;
            this.form[this.rep_entrdtl].gross =
              this.form.book_entries.entry_details[key].amount;
            this.form[this.rep_entrdtl].treasury_id =
              this.form.book_entries.entry_details[key].type;
            this.form[this.rep_entrdtl].detail_date =
              this.form.book_entry.book_date;
            let sdtls = this.update(this.rep_entrdtl, key);
            storedtls.push(sdtls);
          }
        }

        for (const key of this.deletableEntryDetailIds) {
          let sdtls = this.delete("entry_detail", key);
          storedtls.push(sdtls);
        }

        // return Promise.all(storedtls);
        return storedtls;
      }
    },
    onAcconto() {
      if (this.type === "edit") {
        // NOTA: rispetto a toRebate(), qui siano nel contesto di edit e non di create
        let t_id = this.$store.state.auth.treasuries.find(
          (treasury) => treasury.code === "AC"
        ).id;
        const key = Object.keys(this.form.book_entries.entry_details).find(
          (k) => this.form.book_entries.entry_details[k].type === t_id
        );
        // pulisco eventuali NewIndex con type e amount null
        for (const [key, value] of Object.entries(
          this.form.book_entries.entry_details
        )) {
          if (key.toString().startsWith("NewIndex") && !value.type) {
            delete this.form.book_entries.entry_details[key];
          }
        }

        if (!key) {
          // se ancora non c'è nel form, lo aggiungo usando NewIndex-index...
          let index =
            Object.keys(this.form.book_entries.entry_details).filter((e) =>
              e.toString().startsWith("NewIndex")
            ).length + 1;
          this.$set(
            this.form.book_entries.entry_details,
            `NewIndex-${index}`,
            {}
          );
          this.$set(
            this.form.book_entries.entry_details[`NewIndex-${index}`],
            "type",
            t_id
          );
          this.$set(
            this.form.book_entries.entry_details[`NewIndex-${index}`],
            "amount",
            this.entryDetailsCreateTotal() - this.details.gross
          );

          this.selectCreateEntryDetail(
            this.form.book_entries.entry_details,
            t_id,
            index
          );
        } else {
          // ...altrimenti, modifico l'importo
          this.form.book_entries.entry_details[key].amount =
            this.entryDetailsCreateTotal() - this.details.gross;
        }
      }
    },
    atLeastOneTreasuryByCode(code) {
      if (this.type === "edit") {
        return this.item.entry_details.some(
          (entry_detail) =>
            (entry_detail?.treasury?.code === code) & (entry_detail.gross < 0)
        );
      }
    },
    hasEntryDetailByCode(code) {
      if (this.type === "edit") {
        const entryDetailsCodes = Object.keys(this.dtlscfg)
          .filter((key) => this.dtlscfg[key].selected)
          .map((key) => {
            return {
              display: this.$store.state.auth.treasuries.filter(
                (treasury) => treasury.id === this.dtlscfg[key].selected
              )[0].code,
            };
          });
        const check = entryDetailsCodes.find(
          (entryDetailCode) => entryDetailCode.display === code
        );
        if (check) {
          return true;
        }
        return false;
      }
    },
    /* EDIT Method */

    /* Getters [same for both CREATE and EDIT] */
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
      // getTreasuries: "treasuries",
      getExcludedTreasuries: "excludedTreasuries",
      getSalesmen: "salesmen",
      getBookTags: "book_tags",
      getAgencyBroker: "agency_brokers",
      getMandateCodes: "mandate_codes",
    }),
    /* Getters [same for both CREATE and EDIT] */
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_brks() {
      return "breakdownable";
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    prdct() {
      return this.products;
    },
    grss: {
      get() {
        return this.form[this.rep].net + this.form[this.rep].tax;
      },
    },
    grossSumFee() {
      // feegrss()
      return this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
    },
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
    /* CREATE Methods */
    grandGross() {
      return (
        this.grossSumFee +
        (this.isBookEntryBreakdown
          ? sumBreakdowns(this.bookEntryBreakdowns, "net") +
            sumBreakdowns(this.bookEntryBreakdowns, "tax")
          : this.grss)
      );
    },
    bookEntryNetSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.bookEntryBreakdowns, "net"));
      },
    },
    bookEntryTaxSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.bookEntryBreakdowns, "tax"));
      },
    },
    bookEntryGrossSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.bookEntryBreakdowns, "net") +
            sumBreakdowns(this.bookEntryBreakdowns, "tax")
        );
      },
    },
    bookEntryGrossPremiumSum: {
      get() {
        return toLocaleCurrency(
          this.form[this.rep].net + this.form[this.rep].tax
        );
      },
    },
    //Agency
    agencyTakeSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.agencyProvBreakdowns, "take")
        );
      },
    },
    agencyPurchaseSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.agencyProvBreakdowns, "purchase")
        );
      },
    },
    //Saler
    salerTakeSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.salerProvBreakdowns, "take")
        );
      },
    },
    salerPurchaseSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.salerProvBreakdowns, "purchase")
        );
      },
    },
    // DYNAMICALLY ADD ELEMENTS
    trsr: {
      set(val) {
        this.treasuries = val;
      },
      get() {
        return this.treasuries;
      },
    },
    frm: {
      set(val) {
        this.form.book_entries.entry_details = val;
      },
      get() {
        return this.treasuries;
      },
    },
    entryDetailsCreateTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    /* CREATE Methods */
    /* EDIT Methods */
    hasBreakdowns() {
      return this.item.breakdowns.length ? true : false;
    },
    hasBrokerStatement() {
      return this.item && this.item.broker_statement_id ? true : false;
    },
    isImported() {
      // return this.item.insurer.importer_id ? true : false;
      return this.item && this.item.imported_at ? true : false;
    },
    isConsolidated() {
      return this.item && this.consolidated_data.length ? true : false;
    },
    hasEntryDetails() {
      return this.item.entry_details.length ? true : false;
    },
    hasTreasuryRelation() {
      return this.checkTreasuryRelation.length ? true : false;
    },
    isVariousAccounting() {
      return this.type === "create"
        ? true
        : this?.item?.various_accountings?.[0]
        ? true
        : false;
    },
    isInsuranceAncillaries() {
      return this.type === "create"
        ? false
        : this?.item?.insurance_ancillaries?.[0]
        ? true
        : false;
    },
    entryDetailsEditTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    sumEntryDetails() {
      // devo negativizzare la cassa AC...ma solamente nel Totale, non nel componente EntryDetail
      let id = this.$store.state.auth.treasuries.find(
        (treasury) => treasury.code === "AC"
      ).id;
      let clone = JSON.parse(
        JSON.stringify(this.form.book_entries.entry_details)
      );
      let ac = Object.values(clone).find((e) => e.type == id);
      if (ac) {
        ac.amount *= -1;
      }
      return Object.keys(clone)
        .map((key) => {
          return +clone[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    isInsurerAccounting(item) {
      return item?.insurer_accountings?.length > 0 ? true : false;
    },
    /* EDIT Methods */
  },
  created() {
    if (this.type === "create") {
      this.isLoading = true;
      const be = this.fetchCreateForm(this.repository);
      const ed = this.fetchCreateForm(this.rep_entrdtl);
      // other form:
      Promise.all([be, ed]).then(() => {
        this.isLoading = false;
      });
    } else {
      this.fetch();
    }
  },
  mounted() {
    if (this.type === "create") {
      this.companies = this.getInsurers();
      this.risk_branches = this.getBranches()(this.form[this.rep].insurer_id);
      this.risks = this.getRisks();
      this.broker_options = this.getSalesmen();
      this.book_tags_opt = this.getBookTags()
        .filter((e) => e.is_system === "Y")
        .sort((a, b) => a.text.localeCompare(b.text));
      this.agency_broker_id = this.getAgencyBroker()[0]?.value;
      this.initDefaultFormValues();
    }
  },
};
</script>

<style scoped>
.switch {
  margin: 0 0 15px;
  max-width: max-content;
}
</style>
